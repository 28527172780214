/* WHOLE PAGE 
============= */
html {
	/*background-color: #fffffb;*/
	background-color: #000;

	position: relative;
	min-height: 100vh;
}
html, body {
	margin: 0;
	width: 100%;
}

#noscriptMessage {
	font-size: 18px;
	color: #fff;
	text-align: center;
}

#popupScrim {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0,0,0,0.6);
	pointer-events: none;
	z-index: 100;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s cubic-bezier(.8,0,.83,.67), visibility 0 linear 0.2s; 
}
#popupScrim.show {
	pointer-events: auto;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.2s cubic-bezier(.8,0,.83,.67), visibility 0 linear;
}

/* PICTURE TO IMG CONVERSION FIX */
picture > * {
	width: inherit;
	height: inherit;
}

.button {
	display: inline-block;
	padding: 10px 20px;
	border-width: 2px;
	border-style: solid;
}
.button-white {
	border-color: #001434;
	background-color: #fffff6;
	color: #001434;
}
.button-white:hover {
	background-color: #001434;
	color: #fffff3;
}
.button-black {
	border-color: #fffff6;
	background-color: #000;
	color: #fffff6;
}
.button-black:hover {
	background-color: #fffff6;
	color: #000;
}



/* MENU NAVIGATION 
================== */
nav.nav-bar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 1080px;
	height: 40px;
	padding: 0 20px;
	margin: auto;

	box-sizing: border-box;

	/*background-color: #000;*/

	line-height: 40px;
	vertical-align: middle;

	z-index: 1000;

	/*text-align: center;*/
}

.nav-link, nav.nav-bar .nav-vert-bar {
	opacity: 0.75;
}
.nav-link:hover {
	opacity: 1.0 !important;
}

.nav-link {
	padding: 0 16px;
}

.nav-vert-bar {
	display: inline-block;
	background-color: #fffff3;
	height: 24px;
	width: 1px;
	vertical-align: text-top;
	margin: 0 16px;
}

.nav-svg svg {
	display: inline;
	fill: #fffff3;
	width: 28px;

	position: relative;
	top: 5px;
}
.nav-link.nav-svg {
	/*margin: 0 px;*/
}




/* TITLE SECTION 
================ */
#headerSection {
	position: relative;
	max-height: 100vh;
	overflow: hidden;
	box-sizing: border-box;
	/*padding-top: 40px;*/
	max-width: 1400px;
	margin: auto;
}

#headerTitle {
	position: absolute;
	top: 40px;/*80px;*/
	left: 0;
	padding: 10px 16px 10px 60px;
	/*text-align: right;*/
	background-color: rgba(0,0,0,0.5);
}

#headerSection .header-title {
	font-size: 60px;
	margin: 0;
}

#headerSection .header-subtitle {
	margin: 0;
}

#headerSection .title-shift-left {
	margin-left: -26px;
}

#headerSection .header-image {
	display: block;
	width: 100%;
	margin: auto;
}

.scroll-scrim {
	height: 80px;
	background: rgb(0,0,0);
	background: linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(0,0,0,0) 100%);

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	text-align: center;
	line-height: 80px;
	font-weight: 700;
	fill: rgba(255,255,255,0.5);
	font-size: 24px;

	cursor: pointer;

	z-index: 100;
}
.scroll-scrim svg{
	width: 32px;
	height: 32px;
}
.scroll-scrim:hover {
	fill: rgba(255,255,255,1.0);
}

#navMenu {
	position: absolute;
	top: 100%;
	left: 0;
	padding: 10px 0;
}

#navMenu a {
	display: block;
	padding: 6px 0 6px 60px;
	width: 120px;
	text-shadow: 0 0 12px #000;
	cursor: pointer;
}

#navMenu a span {
	position: relative;
	padding-left: 8px;
	border-left: 2px solid #fff;
	box-sizing: border-box;
}
#navMenu a span:before {
	content: '';
	position: absolute;
	top: 0;
	left: -2px;
	width: 2px;
	height: 100%;
	border-bottom: 2px solid #fff;

	transition: width 0.25s ease-out, left 0.25s ease-out;
}
#navMenu a:hover span:before {
	width: 120px;
}

#headerBanner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	line-height: 40px;
	background-color: #000;
	text-align: center;
	color: #efc76f;
	border-color: #efc76f;
}
#headerBanner a {
	text-decoration: underline;

}


/* FILM
======= */
#film {
	display: block;
	width: 100%;
}

#film .film-content {
	width: 95vw;
	max-width: 1080px;
	margin: auto;
	padding: 40px 0;

	box-sizing: border-box;
}

#film .film-title {
	display: block;
	font-size: 54px;
	text-align: center;
	/*padding-top: 10px;*/
	border: solid 2px #001434;
}

#film .film-album-section {
	display: flex;
	flex-direction: row-reverse;
	/*justify-content: space-around;*/
	justify-content: flex-end;
	align-items: center;

	border: 2px solid #001434;
	padding: 20px;
}

#film .film-album-description {
	text-align: center;
	flex-grow: 1;
	padding-left: 20px;
}

#film .social-links {
	padding: 20px 0 0;
}
#film .social-links .nav-svg {
	opacity: 0.8;
}
#film .social-links .nav-svg svg {
	fill: #001434;
	width: 32px;
}

#film .film-image {
	width: 480px;
}

#film .video-wrapper.video-16x9 {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	margin-top: 40px;
}

#film .video-wrapper.video-12x7 {
	position: relative;
	padding-bottom: 58.33%; /* 12:7 */
	height: 0;

	margin-top: 40px;
}

#film .video-wrapper iframe {
	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
}




/* EVENTS
========== */
#events, #pastEvents {
	width: 100%;
	max-width: 800px;
	padding: 40px 0;
	margin: auto;
	text-align: center;
}

.events-wrapper {
	border: solid 1px #fffff6;
	padding: 10px;
}

#pastEvents .past-event-name {
	font-weight: normal;
}

#events #tourWrapper {
	border-bottom:  solid 1px #fffff6;
}

#events .tour-date {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 20px;
}



/* CONTACT
========== */
#contact {
	max-width: 1080px;
	padding: 40px 0;
	margin: auto;
	text-align: center;
}

#contact a {
	text-decoration: underline; 
}




/* FOOTER
========= */
#footer {
	text-align: center;
	padding: 40px;
}





/* GENERIC CLASSES
================== */
a.unstyle-link, .unstyle-link a {
    color: inherit;
    text-decoration: none;
}

a.unstyle-link:hover, a.unstyle-link:focus, .unstyle-link a:hover, , .unstyle-link a:focus {
    color: inherit;
    text-decoration: none;
}

.antialias {
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.line {
	display: inline-block;
}

.mobile-only {
	display: none;
}

.text-header { font-family: 'Montserrat', Helvetica, sans-serif }
.text-sans { font-family: 'Trebuchet MS', Helvetica, sans-serif }
.text-sans-web { font-family: 'Trebuchet MS', Helvetica, sans-serif }
.text-mono { font-family: 'Courier New', Courier, monospace; }

.text-small { font-size: 12px; }
.text-regular { font-size: 16px; }
.text-medium { font-size: 24px; }
.text-large {font-size: 32px; }

.text-black { color: #080808; }
.text-white { color: #fffff3; }
.text-gold { color: #bca20f; }
.text-blue { color: #001434; }

.bg-black { background-color: #000; }
.bg-white { background-color: #fffff6; }
.bg-gold { background-color: #bca20f; }
.bg-blue { background-color: #001434; }

.no-select {
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

/* gold color efc76f */

/* MOBILE
================== */
@media only screen and (max-width: 1023px) {

	.no-mobile {
		display: none;
	}
	.mobile-only {
		display: initial;
	}

	nav.nav-bar {
		display: none;
	}

	#headerSection {
		padding-top: 0;
	}
	#headerSection .scroll-scrim {
		display: none;
	}

	#headerTitle {
		position: static;
		padding: 10px;
		text-align: center;
	}

	#navMenu {
		display: block;
		position: relative;
		text-align: center;
		padding-bottom: 0;
	}
	#navMenu a {
		display: inline-block;
		padding: 4px 12px;
		width: auto;
	}
	#navMenu a span {
		border: none;
		text-align: center;
		padding: 0 4px;
	}
	/*#navMenu a span:before {
		width: 0;
		left: 50%;
	}
	#navMenu a:hover span:before {
		width: 100%;
		left: 0;
	}*/
	#navMenu a span:before {
		width: 100% !important;
		left: 0;
	}

	#headerBanner {
		position: relative;
		height: auto;
		font-size: 20px;
		line-height: initial;
		padding: 8px 0;
		border-bottom: 1px solid;
	}
	#headerBanner .line {
		display: block;
	}

	#film .film-title {
		padding-left: 10px;
		padding-right: 10px;
		margin: 0 5vw 20px;
	}
	#film .film-content {
		width: 100%;
	}
	#film .film-album-section {
		margin: 0 5vw 10px;
		align-items: flex-start;
	}
	#film .film-image {
		width: 360px;
	}

	#contact {
		width: 100%;
	}

}

@media only screen and (max-width: 749px) and (min-width: 600px) {
	#film .film-image {
		width: 240px;
	}
}

@media only screen and (max-width: 599px) {
	#film .film-album-section {
		flex-direction: column-reverse;
		align-items: center;
	}
	#film .film-image {
		width: 100%;
		display: block;
		margin: auto;
		box-sizing: border-box;
	}
	#film .film-album-description {
		margin-top: 20px;
		padding: 0 10px;
	}
	#pastEvents h3 {
		padding: 0 10px;
	}
}

@media only screen and (max-width: 495px) {
	#headerTitle .header-title {
		font-size: 40px;
	}
	#headerTitle .header-subtitle {
		font-size: 18px;
	}
	#headerTitle .title-shift-left {
		margin-left: -18px;
	}

	#navMenu {
		padding: 0;
		font-size: 20px;
	}

	#film .film-title {
		font-size: 32px;
	}
	#film .film-album-description {
		font-size: 18px;
	}

	#events .text-large, #pastEvents .text-large {
		font-size: 24px;
	}
	#pastEvents .text-medium {
		font-size: 18px;
	}

	#contact {
		font-size: 10px;
	}
}

@media only screen and (max-width: 375px) {
	#navMenu a {
		padding: 4px 4px;
	}

	#headerTitle .header-subtitle {
		font-size: 14px;
	}
}

